<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="逻辑删除标记 1:删除,0:未删除" prop="isDeleted" v-if="formType === 1">
              <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记 1:删除,0:未删除" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark" >
              <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
            </a-form-model-item>
            <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
              <a-input v-model="form.version" placeholder="请输入乐观锁" />
            </a-form-model-item>
            <a-form-model-item label="活动id, activity_activity-id" prop="activityId" >
              <a-input v-model="form.activityId" placeholder="请输入活动id, activity_activity-id" />
            </a-form-model-item>-->
      <a-form-model-item label="套餐名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入套餐名称"/>
      </a-form-model-item>
      <a-form-model-item label="物品名称" prop="goods">
        <a-input v-model="form.goods" placeholder="请输入物品名称"/>
      </a-form-model-item>
      <a-form-model-item label="套餐金额" prop="payAmount">
        <!--        <a-input v-model="form.payAmount" placeholder="请输入套餐金额"  />-->
        <a-input v-model="form.payAmount" placeholder="请输入套餐金额" @input.native="formatNum($event,index)"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="活动奖励，多图" prop="img" >-->
      <!--        <a-input v-model="form.img" placeholder="请输入内容" type="textarea" allow-clear />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="活动奖励" prop="img">
        <OssMultiUploadSingle v-model="form.img" type="img" :maxSize="10"></OssMultiUploadSingle>
      </a-form-model-item>
      <a-form-model-item label="活动奖励展示" prop="showi">
        <OssMultiUploadSingle v-model="form.showi" type="img" :maxSize="10"></OssMultiUploadSingle>
      </a-form-model-item>
      <a-form-model-item label="奖励类型实物还是虚拟" prop="prizeType">
        <a-select style="width: 100%" v-model="form.prizeType" placeholder="请输入标签">
          <a-select-option v-for="(item, index) in this.customDict.PrizeTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getActivityReward, addActivityReward, updateActivityReward} from '@/api/activity/activityReward'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'
import OssMultiUploadSingle from '@/views/script/script/modules/OssMultiUploadSingle.vue'
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from "vuex";

export default {
  name: 'CreateForm',
  props: {},
  components: {
    OssMultiUpload, OssMultiUploadSingle,CustomDictTag
  },
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        activityId: null,

        name: null,

        goods: null,

        payAmount: null,
        showi: null,
        img: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          {required: true, message: '套餐名称不能为空', trigger: 'blur'}
        ],

        goods: [
          {required: true, message: '商品不能为空', trigger: 'blur'}
        ],
        img: [
          {required: true, message: '活动奖励图片不能为空', trigger: 'blur'}
        ],
        showi: [
          {required: true, message: '活动奖励图片不能为空', trigger: 'blur'}
        ],
        prizeType: [
          {required: true, message: '实物还是虚拟', trigger: 'blur'}
        ],
        payAmount: [
          {required: true, message: '套餐金额不能为空', trigger: 'blur'}
        ]

      }
    }
  },
  filters: {},
  created() {
  },
  computed: {    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,

        createTime: null,

        updateTime: null,

        creator: null,

        modifier: null,

        isDeleted: null,

        remark: null,

        version: null,

        activityId: null,

        name: null,

        goods: null,

        payAmount: null,

        showi: null,
        img: null

      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    //限制
    // check_num: function(){
    //   this.form.payAmount = this.form.payAmount.replace(/[^\a-\z\A-\Z0-9\u4e00-\u9fe5_]/g, '');
    // },
    formatNum(e, index) {
      // 方法1
      console.log(e, index)
      console.log(e.target.value)
      const pattern = /([1-9]+[\d]*(\.[0-9]{0,2})?)/
      const match = e.target.value.match(pattern)
      console.log(match)
      if (match) {
        e.target.value = e.target.value.replace(e.target.value, match[0])
      } else {
        e.target.value = ''
      }
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getActivityReward(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateActivityReward(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addActivityReward(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
