import request from '@/utils/request'

// 查询活动奖励套餐列表
export function listActivityReward(query) {
  return request({
    url: '/activity/activity/reward/list',
    method: 'get',
    params: query
  })
}

// 查询活动奖励套餐详细
export function getActivityReward(id) {
  return request({
    url: '/activity/activity/reward/' + id,
    method: 'get'
  })
}

// 新增活动奖励套餐
export function addActivityReward(data) {
  return request({
    url: '/activity/activity/reward',
    method: 'post',
    data: data
  })
}

// 修改活动奖励套餐
export function updateActivityReward(data) {
  return request({
    url: '/activity/activity/reward',
    method: 'put',
    data: data
  })
}

// 删除活动奖励套餐
export function delActivityReward(id) {
  return request({
    url: '/activity/activity/reward/' + id,
    method: 'delete'
  })
}
